<template>
  <div class="text-dark">
    <b-modal id="modal-response" v-model="modalVisible" centered class="p-3">
      <div slot="modal-header" class="d-flex align-items-center justify-content-between p-2 w-100">
        <span style="color: var(--biz-brand-button)" class="h4 mb-0">Rincian Pembayaran</span>
        <span @click="modalVisible = false" class="mb-0" style="cursor: pointer;">
          <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.2246 5.0175L15.7423 12.499L23.2246 19.9805C24.2553 21.0125 24.2553 22.6877 23.2273 23.7226C22.1939 24.76 20.5209 24.7574 19.4875 23.7253L12 16.2385L4.51247 23.7253C3.47909 24.7574 1.80609 24.76 0.772705 23.7226C-0.258011 22.6879 -0.258013 21.0127 0.775369 19.9805L8.25769 12.499L0.775369 5.0175C-0.258013 3.98543 -0.258011 2.31023 0.772705 1.27536C1.28813 0.756718 1.96732 0.5 2.64398 0.5C3.32065 0.5 3.99717 0.759386 4.5126 1.27282L12.0001 8.75964L19.4876 1.27282C20.0031 0.756716 20.6796 0.5 21.3562 0.5C22.0329 0.5 22.7121 0.759386 23.2275 1.27536C24.258 2.31023 24.258 3.98543 23.2246 5.0175Z" fill="var(--biz-brand-button)"/>
          </svg>
        </span>
      </div>
      <div class="d-flex align-items-center justify-content-between p-2">
        <span class="title">Total Harga ({{ detailOrder && detailOrder.items && detailOrder.items.length }} Produk) </span>
        <span class="value" style="color: var(--biz-secondary-100);">{{ toCurrency(subTotal) }} Koin</span>
      </div>
      <div slot="modal-footer" class="d-flex align-items-center justify-content-between w-100 p-2">
        <span class="title-bold">Total Tagihan</span>
        <span class="value-bold" style="color: var(--biz-secondary-100);">{{ toCurrency(detailOrder && detailOrder.sub_total) }} Koin</span>
      </div>
    </b-modal>
    <h2 class="mb-5">Pembayaran</h2>
    <div class="d-flex justify-content-center">
      <div class="card align-items-center w-75" style="padding: 24px 100px">
        <div class="card-body col">
          <div class="d-flex mb-4" style="gap: 0px 20px">
            <div class="d-flex align-items-center">
              <WalletOutlined color="var(--biz-brand-button)"/>
              <div class="ml-2" style="color: var(--biz-brand-button)">Koin Loyalty</div>
            </div>
          </div>
          <div class="d-flex justify-content-between mb-5">
            <div class="d-flex flex-column">
              <span>Total Pembayaran</span>
              <div class="d-flex align-items-center justify">
                <span style="color: var(--biz-secondary-100);">{{ toCurrency(detailOrder && detailOrder.sub_total) }} Koin</span>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-end">
              <span class="text-hover btn-rincian-pembayaran button-link" @click="toggleModal">Lihat Rincian Pembayaran</span>
            </div>
          </div>
          <div class="card border-0 shadow-none d-flex align-items-end" style="gap: 20px 0px;">
            <button
              @click="$router.push({ path: '/loyalty-redeem/purchase/' })"
              class="btn btn-bottom w-auto button-primary"
              style="color: white; background-color: var(--biz-brand-button) !important"
            >
              Lihat Daftar Transaksi
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import WalletOutlined from '@/components/Icons/WalletOutlined.vue'

export default {
  name: 'PaymentTutorial',
  components: {
    WalletOutlined,
  },
  props: ['loyaltyUsage', 'subTotal', 'detailOrder'],
  data: function () {
    return {
      id_params: this.$route.params.order_id,
      data: {},
      activeKey: '',
      modalVisible: false,
      countDown: moment().format('HH:mm:ss'),
    }
  },
  computed: {
    time: function () {
      const time = this.countDown
      const resultTime = moment.utc(time).format('HH:mm:ss')
      return this.countDown <= 0 ? '00:00:00' : resultTime
    },
  },
  methods: {
    getDifferenceInHours(expiredDate, currentDate) {
      const differentTime = expiredDate - currentDate
      const checkIfExpired = differentTime <= 0 ? 0 : moment(expiredDate) - moment(currentDate)
      return checkIfExpired
    },
    getOrderDetail() {
      this.$store.dispatch('purchase/GETINVOICEDETAIL', {
        order_id: this.id_params,
      })
        .then((res) => {
          const result = res.data
          this.data = {
            ...result,
            total: Intl.NumberFormat('en-US').format(result.gross_amount),
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    changeActiveKey(key) {
      this.activeKey = key
    },
    toggleModal() {
      this.modalVisible = !this.modal
    },
    onError() {
      return this.$notification.error({
        message: 'Copy to Clipboard Failed',
      })
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
  },
  mounted() {
    this.getOrderDetail()
    setInterval(() => {
      this.countDown = moment(this.countDown).subtract(1, 'seconds')
    }, 1000)
  },
  watch: {
    // this.countDownTimer()
  },
}
</script>

<style scoped>
.ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}

.text-hover:hover {
  color: var(--biz-brand-button);
  cursor: pointer;
}
.button-link {
  font-size: 14px;
}

.button-primary {
  padding: 4px 24px;
  border-radius: 8px;
  font-size: 16px;
}
.title {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.title-bold {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.value {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: right;
}
.value-bold {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: right;
}
.btn-rincian-pembayaran:hover {
  font-weight: bold;
  text-decoration: underline;
}
</style>
