<template>
  <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.01562 6.1956V2.8039C1.01562 1.80713 1.82273 1 2.81949 1H20.4347C21.4315 1 22.2386 1.80713 22.2386 2.8039V3.93741" :stroke="color || '#005CA9'" stroke-miterlimit="10"/>
    <path d="M22.737 20.1708H3.68834C2.24172 20.1708 1.01562 19.3284 1.01562 18.2037V2.6875C1.01562 2.6875 1.01563 3.93568 3.7104 3.93568H22.737C23.5573 3.93568 24.2277 4.60167 24.2277 5.42644V18.6845C24.2277 19.5048 23.5573 20.1708 22.737 20.1708Z" fill="white" :stroke="color || '#005CA9'" stroke-miterlimit="10"/>
    <path d="M18.4112 9.78516H24.255C24.6652 9.78516 25.0004 10.1204 25.0004 10.5305V13.6973C25.0004 14.1075 24.6652 14.4427 24.255 14.4427H18.4112C17.1234 14.4427 16.0781 13.3974 16.0781 12.1139C16.0825 10.8305 17.1234 9.78516 18.4112 9.78516Z" :fill="color || '#005CA9'"/>
    <path d="M2.82422 5.23438H22.9622" :stroke="color || '#005CA9'" stroke-miterlimit="10" stroke-linecap="round" stroke-dasharray="2.8 2.8"/>
    <path d="M2.82422 18.8516H22.9622" :stroke="color || '#005CA9'" stroke-miterlimit="10" stroke-linecap="round" stroke-dasharray="2.8 2.8"/>
    <path d="M18.9168 12.0971C18.9168 12.5293 18.5683 12.8778 18.1361 12.8778C17.7039 12.8778 17.3555 12.5293 17.3555 12.0971C17.3555 11.6649 17.7039 11.3164 18.1361 11.3164C18.5683 11.312 18.9168 11.6649 18.9168 12.0971Z" fill="white"/>
    <path d="M13.7787 12.1182C13.7787 14.9542 11.4809 17.2477 8.64937 17.2477C5.81346 17.2477 3.51562 14.9498 3.51562 12.1182C3.51562 9.28226 5.81346 6.98438 8.64937 6.98438C11.4809 6.98438 13.7787 9.28226 13.7787 12.1182Z" :fill="color || '#005CA9'"/>
    <path d="M1.01611 3.01757C1.01611 3.01757 0.676508 1.00196 3.11547 1.00196C5.55444 1.00196 20.1221 1.00196 20.1221 1.00196C20.1221 1.00196 22.2347 0.834359 22.2347 3.59976C17.2818 3.59976 3.33599 3.59976 3.33599 3.59976C3.33599 3.59976 1.6468 3.78059 1.01611 3.01757Z" :fill="color || '#005CA9'"/>
    <path d="M9.03905 8.97585L9.78882 10.4931C9.85057 10.621 9.97406 10.7092 10.1152 10.7312L11.7912 10.9738C12.1484 11.0267 12.2895 11.4634 12.0337 11.7148L10.8209 12.8968C10.7194 12.9982 10.6709 13.1394 10.6974 13.2805L10.984 14.9477C11.0458 15.305 10.6709 15.574 10.3534 15.4064L8.85823 14.6213C8.73033 14.5552 8.58037 14.5552 8.45247 14.6213L6.95732 15.4064C6.63536 15.574 6.26489 15.305 6.32664 14.9477L6.61331 13.2805C6.63536 13.1394 6.59127 12.9938 6.48983 12.8968L5.27696 11.7148C5.01675 11.4634 5.16229 11.0223 5.51954 10.9738L7.19549 10.7312C7.33663 10.7092 7.46013 10.621 7.52187 10.4931L8.27164 8.97585C8.41718 8.65388 8.88027 8.65388 9.03905 8.97585Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'WalletOutlinedIcon',
  props: ['color'],
}
</script>

<style>

</style>
